import { ReactElement } from 'react'
import './App.css'

const App = (): ReactElement => {
  return (
    <div className="App">
      <h1>Tumblife</h1>

      <section>
        <p className="caution">Tumblife development was ended in accordance with the change of the Tumblr API.</p>
      </section>

      <section>
        <h2 id="about">About</h2>
        <p>Tumblife is App's that can viewing comfortable of Tumblr Dashboard for Rebloger.</p>
      </section>

      <section>
        <h2 id="android">Tumblife for Android</h2>
        <p>Android OS 1.6+</p>

        <section>
          <h3>Description</h3>
          <ul>
            <li>Like and Reblog only.</li>
            <li>Can be seen up to 300-250 posts.</li>
            <li>Can skip the photo post and your post.</li>
            <li>To pin up the post, can Reblog or Like later.</li>
          </ul>
        </section>

        <section>
          <h3>Attention</h3>
          <ul>
            <li>Suspend app if you press back button.</li>
            <li>"Clear Cache" option operate at exit by menu.</li>
          </ul>
        </section>

        <section>
          <h3>Screenshotos</h3>
          <section className="screenshotos">
            <p className="left">
              <a href="/tumblife_for_android_ss_1_l.png">
                <img src="/tumblife_for_android_ss_1_s.png" alt="Dashboard" />
              </a>
            </p>
            <p className="right">
              <a href="/tumblife_for_android_ss_2_l.png">
                <img src="/tumblife_for_android_ss_2_s.png" alt="Reblog All Pin Posts" />
              </a>
            </p>
          </section>
        </section>

        <section>
          <h3>Downloads</h3>
          <p><del>Tumblife - Android Market</del></p>
        </section>

        <section>
          <h3>Repository</h3>
          <p><a href="https://github.com/mitukiii/TumblifeForAndroid">GitHub</a></p>
        </section>

        <section>
          <h3>Development Build</h3>
          <p><del>Tumblife.apk</del></p>
        </section>
      </section>

      <section>
        <h2 id="developer">Developer</h2>
        <p><a href="http://twitter.com/mitukiii" rel="external nofollow">@mitukiii</a></p>
      </section>
    </div>
  )
}

export default App
